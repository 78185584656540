.root {
    width: 100%;
}

.header {
    display: grid;
    grid-template-columns: 10fr 1fr;
    justify-items: left;
    grid-template-rows: auto;
    cursor: pointer;
    height: 43px;
    padding: 10px 30px 10px 12px;
    border: solid 1px var(--intel-gray-light);
    border-radius: 4px;
    width: 100%;
    position: relative;
    font-weight: bolder;
    color: var(--intel-text);
    font-size: 14px;
    line-height: 1.5;
    background-color: var(--intel-white);
}

.header:after {
    content: "";
    display: block;
    border: solid 3px var(--intel-dark);
    width: 6px;
    height: 6px;
    border-radius: 5px;
    transform: rotate(45deg);
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    right: 10px;
    top: 35%;
}

.toggleable {
    position: absolute;
    background: #fff;
    border: solid 1px var(--intel-gray-light);
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 10;
    align-items: stretch;
}

.scrollable {
    flex: 1;
    overflow: auto;
}

.inputSearchMessage,
.inputSearchSpan {
    display: none;
}

.inputSearch {
    font-size: 14px;
    border-radius: 0px;
    min-height: 40px;
    padding: 10px 12px;
    border: 1px solid var(--intel-gray-light);
    border-left: none;
    border-right: none;
    font-family: var(--font-main);
    line-height: 1.5;
    transition: border-color 350ms ease-in-out;
    width: 100%;
    display: block;
}

.checkboxWrapper {
    display: flex;
    cursor: pointer;
    height: 43px;
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    width: 100%;
}

.title {
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-text);
    margin-bottom: 5px;
}

.button__clear {
    /*padding: 5px;*/
    right: 10px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.button__clear:after {
    content: "x";
    font-weight: bolder;
    color: var(--intel-dark);
    margin-left: 10px;
}


.button__clear:hover {
    transform: scale(1.5);
}


.header__info {
    flex:1;
    text-align: end;
}

.errorMessage {
    font-family: var(--font-main);
    color: var(--intel-negative);
    font-size: 12px;
    margin: 7px 4px;
    font-weight: 400;
}

.errorBorder {
    border-color: var(--intel-negative);
}

.option {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 43px;
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--intel-gray-light);
    background-color: inherit;
}

.option__selected {
    background-color: var(--intel-dark);
    color: var(--intel-white);

}