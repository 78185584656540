.root {
    width: calc(100% - 20px);
    max-width: 1600px;
    background-color: var(--intel-white);
    margin: 10px auto;
}

.import__button {
    margin: 10px 0;
    font-size: 0.7rem;
}