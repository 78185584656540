.root_logged {
    width: 100%;
    padding-top: 58px;
    min-height: 100vh;
}
.root_notLogged {
    width: 100%;
    height: 100%;
    padding: 30px;
    min-height: 100vh;
}

.header {
    width: 100%;
    height: 58px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0
}
.content{
    display: flex;
    align-items: flex-start;
}
.aside {
    width: 0;
    height: calc(100vh - 58px);
    background-color: var(--intel-dark2);
    position: fixed;
    top: 58px;
    left: 0
}

.main{
    width: 100%;
    background-color: var(--bg);
    padding-right: 20px;
    transition: padding-left ease-in-out 300ms;
    height: 100%;
}

.root_expanded .main {
    padding-left: 220px;
}

.root_folded .main {
    padding-left: 78px;
}

.root_logged .main {
    min-height: calc(100vh - 58px);
}

.root_notLogged .main {
    min-height: calc(100vh - 60px);
}

.root_notLogged .main {
    padding: 0;
}