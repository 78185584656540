.root {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 10px 0;
}

.zoom {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    min-height: 40px;
    padding: 0 12px;
    border: 1px solid var(--intel-gray-light);
}

.zoom__label {
    font-family: var(--font-main);
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-gray-light);
    line-height: 1.5;
    margin-right: 5px;
}

.zoom__range {
   flex: 1
}

.popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 30px 50px 30px;
    width: 100%;
}

.popup__info {
    padding-bottom: 30px;
    width: 100%;
    text-align: center;
}

.primaries {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.secondaries {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.unusable_button {
    width: 167px;
}