.root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(525px, 1fr));
    grid-gap: 10px;
}

.wrapper {
    background-color: var(--intel-white);
}

.dates_wrapper {
    display: flex;
    justify-items: center;
}

.field_date {
    padding: 0 5px;
    width: 350px;
}

.button {
    height: 72px;
    padding-top: 30px;
    width: 125px;
    flex-shrink: 0;
    flex-grow: 0;
}

.field_select {
    padding: 0 5px;
}