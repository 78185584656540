.root {
    width: 100%;
}
.label {
    font-family: var(--font-main);
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-text);
}

.title {
    font-family: var(--font-main);
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-text);
    margin-bottom: 5px;
}

.wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 43px;
    padding: 10px 12px;
    border-radius: 4px;
    width: 100%;
}

.input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
}

.switch {
    width: 35px;
    height: 22px;
    border-radius: 20px;
    border: solid 2px;
    transition: border-color 300ms ease-in-out, background-color 300ms ease-in-out;
    margin-right: 12px;
}

.switch:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: transform 300ms ease-in-out;
}

.root_unchecked .switch:after {
    transform: translateX(0);
    background-color: var(--intel-text2);
}

.root_checked .switch:after {
    transform: translateX(13px);
    background-color: var(--intel-white);
}

.root_unchecked .switch {
    background-color: var(--intel-dark);
    border-color: var(--intel-dark);
}

.root_checked .switch {
    background-color: var(--intel-link-hover);
    border-color: var(--intel-link-hover);
}

.message {
    font-family: var(--font-main);
    color: var(--intel-negative);
    font-size: 12px;
    margin: 7px 4px;
    font-weight: 400;
}
