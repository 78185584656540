.popup {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.popup__message {
    font-size: 16px;
}

.popup__name {
    font-size: 16px;
    font-weight: bolder;
}

.popup__actions {
    padding: 20px 0 40px 0;
}

.popup__open,
.popup__close {
    background-color: transparent;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
}

.popup__open:hover {
    transform: scale(1.1)
}

.popup__open:active {
    transform: scale(0.9)
}