.root {
    background-color: var(--intel-white);
    width: 100%;
    max-width: 1200px;
    margin: 20px auto 0 auto
}

.title {
    padding: 20px;
    font-family: var(--font-main);
    font-size: 14px;
    font-weight: 600;
    border-bottom: solid 2px var(--bg);

}

.content {
    padding: 20px;
}

.form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.inputRoot {
    padding-right: 20px;
    width: 25%;
}

.checkboxRoot {
    padding-right: 20px;
    width: 25%;
}

.inputLabel {
    font-family: var(--font-main);
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-text);
    margin-bottom: 5px;
}

.formContent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.submit {
    margin-left: auto;
}