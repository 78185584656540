:root {
    /**
    * colors 
    * ********/

    /* dark, backgrounds */
    --intel-dark: #022e3d;
    --intel-dark2:#022734;

    /* colorful, active elements */
    --intel-light: #4DA1D9;
    --intel-link: #009EE0;
    --intel-link-hover: #0082BA;
    --intel-configm: #06C267;
    --intel-negative: #FF4F4F;

    /* grays, text */
    --bg: #f7f7f7;
    --intel-white: #fff;
    --intel-text2: #a4abb3;
    --intel-gray-light: #b3b3b3;
    --intel-text3: #4b4b4b;
    --intel-text4: #465056;
    --intel-text: #434D53;

    /* notices */
    --notice-success: rgb(6, 194, 103, .8);
    --notice-failure: rgb(255, 79, 79, .8);
    --notice-neutral: rgb(0, 130, 186, .8);
    --notice-warning: rgb(255, 161, 0);

    /* font */
    --font-main: 'Avenir', sans-serif;
}