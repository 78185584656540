.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
    width: 100%;
    padding: 15px;
    max-width: 530px;
    margin: 0 auto
}

.logo {
    height: 68px;
    width: auto;
    margin-bottom: 5px;
}

.subtitle {
    font-family: var(--font-main);
    color: var(--intel-text2);
    font-size: 12px;
    margin-bottom: 20px;
}

.forgotPassword {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0 30px 0;
}


.rules__wrapper {
    width: 100%;
    padding: 20px;

}
.rules__title {
    color: var(--intel-text);
    font-size: 20px;

}
.rules__list {
    font-size: 14px;
    color: var(--intel-text);
    padding: 20px;
}
.title {
    color: var(--intel-text);
    font-size: 20px;
    padding: 20px;
}