@font-face {
    font-family: 'Avenir';
    src: url(./../assets/fonts/Avenir-Black.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Black.woff) format('woff'),
    url(./../assets/fonts/Avenir-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(./../assets/fonts/Avenir-Heavy.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Heavy.woff) format('woff'),
    url(./../assets/fonts/Avenir-Heavy.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(./../assets/fonts/Avenir-Medium.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Medium.woff) format('woff'),
    url(./../assets/fonts/Avenir-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(./../assets/fonts/Avenir-Light.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Light.woff) format('woff'),
    url(./../assets/fonts/Avenir-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Book';
    src: url(./../assets/fonts/Avenir-Book.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Book.woff) format('woff'),
    url(./../assets/fonts/Avenir-Book.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(./../assets/fonts/Avenir-Roman.woff2) format('woff2'),
    url(./../assets/fonts/Avenir-Roman.woff) format('woff'),
    url(./../assets/fonts/Avenir-Roman.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
