.root {
    display: flex;
    flex-wrap: wrap;
}

.field {
    flex: 1;
    min-width: 306px;
    padding: 10px;
}

.actionField {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}