.root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 25px;
    justify-content: center;
    padding: 30px 0;
}

.message {
    padding: 30px 0;
}

.chartWrapper {
    background-color: var(--intel-white);
}