.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: solid 1px var(--bg);
    height: 44px;
    font-size: 13px;
}

.row__id {
    padding-left: 10px;
    width: 20%
}

.row__name{
    padding-left: 10px;
    width: 50%
}

.row__actions {
    padding-left: 10px;
    width: 30%
}

.header {
    composes: row;
    font-size: 13px;
    font-weight: bolder;
}

.header__id {
    composes: row__id
}

.header__name{
    composes: row__name
}

.header__actions{
    composes: row__actions
}