.root {

}

.voteFor {
    padding-top: 10px
}

.voteFor * {
    margin-right: 10px;
}

.communicate {
    padding: 11px 0;
}