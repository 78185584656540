.root {
    width: 100%;
}

.regions__list {
    list-style: none;
    margin: 0;
    padding: 0;
    min-height: 200px;
    max-height: 370px;
    overflow: auto;
}

.checkboxWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 43px;
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    width: 100%;
}

.popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 30px 50px 30px;
    width: 100%;
}

.popup__info {
    padding-bottom: 30px;
    width: 100%;
    text-align: center;
}

.finishbar {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
}

.regions__item {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
}
.regions__annotation {
    width: 50%;
    /*max-width: 400px;*/
}

.regions__actions {
    width: 30%;
    /*max-width: 300px;*/
}

.regions__input {
    composes: regions__annotation;
    position: relative;
}

.input {
    border-radius: 4px;
    padding: 5px 6px;
    border: 1px solid var(--intel-gray-light);
    font-family: var(--font-main);
    transition: border-color 350ms ease-in-out;
    width: 100%;
    display: block;
}
.inputMessage {
    display: none;
}

.region__input__suggestions {
    position: absolute;
    top: 55px;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    background-color: var(--intel-white);
    border: 1px solid var(--intel-gray-light);
    transform: translateY(-25px);
}

.region__input__suggestions__item {
    padding-left: 20px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid var(--intel-gray-light);
    cursor: pointer;
}
.region__input__suggestions__item:last-child {
    border-bottom: none;
}

.region__input__suggestions__item__hovered {
    background-color: var(--intel-gray-light);
}

.regions__range{
    width: 20%;
    display: flex;
    align-items: center;
}

.regions__rangeStart {
    padding-right: 5px;
    width: 50%;
}

.regions__rangeEnd {
    width: 50%;
}

.regions__item:first-of-type {
    /*padding: 50px 0 20px 0;*/
    font-weight: bolder;
    align-items: center;
}

.regions__color__wrapper {
    margin: 2px;
    cursor: pointer;
}

.regions__color {
    width: 15px;
    height: 15px;
    margin: 3px;
    cursor: pointer;
}

.action__button {
    padding: 5px 5px !important;
}

.action__button__unusable {
    color: var(--intel-negative)!important;;
}
.action__button__accepted {
    color: #0b8000!important;;
}

.region__unusable {
    background-color: var(--intel-gray-light);
}
.region__accepted {
    background-color: var(--notice-success);
}

.submit__button_wrapper {
    display: flex;
    flex-direction: column;
}

.submit__button_warning {
    color: var(--intel-gray-light);
}

.unusable__file__info {
    width: 100%;
    height: 50px;
    background-color: var(--intel-gray-light);
    padding: 12px;
    border-radius: 7px;
}