
.root {
    font-size: 12px;
    font-weight: 400;
    color: var(--intel-text4);
    text-decoration: underline;
    background: transparent;
    letter-spacing: .4px;
    padding: 10px 20px;
    transition: all .2s;
    border: none;
    font-family: var(--font-main);
    cursor: pointer;
    line-height: 1.25;
}

.root:hover {
    cursor: pointer;
    color: var(--intel-light);
    text-decoration: none;
}