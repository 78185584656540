.root {
    padding: 30px 0;
}

.message {
    padding: 30px 0;
}

.tableWrapper {
    background-color: var(--intel-white);
    width: 100%;
}

.tableWrapper table {
    width: 100%;
    border-collapse: collapse;
}
.tableWrapper th,td {
    border: 1px solid var(--intel-gray-light);
    padding: 5px 10px;
    color: var(--intel-dark2);
    opacity: 0.8;
    text-align: left;
    font-size: 18px;
}