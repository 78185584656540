.root {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.popup {
    padding: 20px;
}

.popup__info {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px
}
.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.input{
    width: 100%;
    max-width: 350px;
}
.footer {
    margin-top: 20px
}