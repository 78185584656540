.root {
    height: 200px;
}

.legendWrapper {
    overflow: hidden;
    height: 200px;
}

.legend {
    padding: 0px;
    margin: 0px;
    text-align: left;
    height: 160px;
    width: 200px;
    overflow: auto;
}

.legend__button {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 10px var(--intel-dark);
    border-top-color: transparent;
    border-right-color: transparent;
    background: none;
    cursor: pointer;
}

.legend__button_down {
    composes: legend__button;
    transform: translateY(-8px) rotate(-45deg);
}

.legend__button_up {
    composes: legend__button;
    transform: translateY(6px) rotate(135deg);
}

.legend__buttons {
    display: flex;
    height: 20px;
    width: 100%;
    justify-content: center;
}
.legend_item {
    display: block;
    margin-right: 10px;
    cursor: pointer;
}

.legend__square {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
}

.pie {
    cursor: pointer;
}