.header {
    display: flex;
    align-items: flex-end;
}

.button {
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    border: none;
    cursor: pointer;
    position: relative;
    top: 1px;
    transition: all 300ms ease-out;
    z-index: 1;
}

.button_active {
    composes: button;
    padding: 10px 25px;
    background-color: var(--intel-white);
    color: var(--intel-link);
    font-weight: bold;
    -webkit-box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.button_inactive {
    composes: button;
    background-color: var(--bg);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.tab {
    padding: 20px;
    background-color: var(--intel-white);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    position: relative;
}