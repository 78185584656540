.row {
    display: grid;
    grid-template-columns: 2fr 5fr 6fr 4fr 2fr 2fr 2fr 3fr;
    grid-template-rows: auto;
}

.row__cell {
    padding: 10px;
    display: flex;
    align-items: center;
}

.row__cell_form {
    padding-left: 0;
}

.title {
    font-family: var(--font-main);
    color: var(--intel-text);
    font-weight: 600;
    font-size: 13px
}

.row__id {
    composes: row__cell;
    flex:1;
    flex-basis: 40px;
    display: flex;
    justify-content: center;
}

.row__user {
    composes: row__cell;

}

.row__email {
    composes: row__cell;
}

.row__company {
    composes: row__cell;
}

.row__role {
    composes: row__cell;
}

.row__status {
    composes: row__cell;
}

.row__actions {
    composes: row__cell;
    display: flex;
    justify-content: center;
}

.content {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    color: var(--intel-dark);
    font-family: var(--font-main);

    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
}

.footer {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
}

.listItem:hover{
    background-color: var(--intel-link-hover);
}

.actionButton {
    border: none;
    background: none;
    width: 30px;
    height:30px;
    cursor: pointer;
}

.actionButton:hover {
    transform: scale(1.1)
}

.actionButton:active {
    transform: scale(0.9)
}

.actionButton:first-of-type {
    margin-right: 10px;
}