.root {
    position: fixed;
    right: 30px;
    top: 30px;
    width: 100%;
    max-width: 300px;
    z-index: 1000;
}

.message {
    display: flex;
    width: 100%;
    max-width: 300px;
    padding: 20px;
    background-color: var(--notice-neutral);
    color: white;
    font-family: var(--font-main);
    border: none;
    outline: none;
    margin-bottom: 10px;
    align-items: center;
}

.message::before {
    content: "i";
    font-size: 15px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px var(--intel-white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    opacity: .5;
}

@keyframes slidein {
    from {
        transform: translateX(110%);
        opacity: 0
    }
    80% {
        transform: translateX(-7%);
        opacity: 1
    }
    to {
        transform: translateX(0);
        opacity: 1
    }
}

.fail {
    composes: message;
    background-color: var(--notice-failure);
}

.fail:before {
    content: "!"
}

.success {
    composes: message;
    background-color: var(--notice-success);
}

.success:before {
    content: "OK";
    font-size: 10px;
    line-height: 10px;
}

.message:hover, .success:hover, .fail:hover {
    cursor: pointer;
}

.notice__animationSlideInNotice {
    animation: slidein 250ms ease-out forwards;
}

.notice__transitionOnCancelNotice {
    transition: height 250ms ease-in-out, transform 250ms ease-in-out;
    overflow: hidden;
}

.notice__message {
    width: calc(100% - 40px);
    text-align: left;
}