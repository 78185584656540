.root {
    display: block;
}

.item:not(.item:last-of-type){
    border-bottom: solid 1px var(--bg);
    display: flex;
    align-items: center;
}

.item {
    display: flex;
    align-items: center;
    padding: 15px 0;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    position: relative
}

.label:after {
    content: "";
    display:block;
    cursor: pointer;
    border: solid var(--intel-gray-light);
    border-width: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 2;
    background-color: var(--bg);
    transition: border-width 200ms ease-out
}

.label:hover::after {
    border-width: 1px;
}

.label:before {
    content: "";
    display:block;
    cursor: pointer;
    border-radius: 50%;
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 3;
    background-color: var(--intel-link);
    transition: width 300ms ease-out, height 300ms ease-out
}

.label_checked:before {
    width: 30px;
    height: 30px;
}

.input {
    position: absolute;
    z-index: 0;
}

.audioWrapper {
    display: flex;
    align-items: center;
}