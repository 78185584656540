
.details__wrapper {
    display: flex;
}

.details__list__wrapper {
    width: 200px;
}

.details__list {
    list-style-position: inside;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}