.root {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.positioner {
    position: relative;
}

.button {
    display: inline-flex;
    width: 35px;
    height: 35px;
    background-color: var(--bg);
    border:none;
    font-size: 11px;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background-color: var(--intel-text2);
    cursor: pointer;
}

.button__step {
    composes: button;
    opacity: 1;
    color: inherit;
}

.button__step:disabled {
    opacity: 1;
    color: inherit;
    pointer-events: none;
}

.button__step__previous {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: solid 2px var(--intel-white);
}

.button__step__next {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: solid 2px var(--intel-white);
}


.result{
    composes: button;
}

.result_active {
    composes: result;
    background-color: var(--intel-text2);
}
.result_active:disabled {
    opacity: 1;
    color: inherit;
    pointer-events: none;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg);
}

.loading::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border: solid 5px var(--intel-gray-light);
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 300ms ease-in-out infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg);
    }
}