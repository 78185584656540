* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: var(--font-main);
    scroll-behavior: smooth;
}

h1, h2, h3 {
    font-family: sans-serif;
}

h1, h2 {
    padding: 0 20px
}

h3 {
    text-transform: uppercase;
    line-height: 1em;
    font-size: 1em;
}

body {
    background-color: var(--intel-dark2);
}