 .root {
     display: block;
 }

 .row {
     display: flex;
     align-items: stretch;
     width: 100%;
     padding: 10px 0 10px 0;
 }

 .message {
     composes: row;
     height: 66px;
     align-items: center;
 }

 .row:not(.row_header):hover {
     background-color: var(--intel-gray-light)
 }

 .row_header {
     composes: row;
     font-weight: bold;
     cursor: auto;
     border-width: 0 0 2px 0;
     border-style: solid;
     border-color: var(--bg);
     padding: 5px 0 10px 0;
     align-items: flex-end;
 }

 .cell {
     word-wrap: break-word;
     padding: 5px 10px;
     font-size: 13px;
     display: flex;
     align-items: center;
     border-right-width: 1px;
     border-right-style: solid;
     border-right-color: var(--bg);
     text-overflow: ellipsis;
     overflow: hidden;
 }

 .cell:last-of-type {
     border-right-width: 0;
 }

 .row_header .cell {
     display: flex;
     flex-wrap: wrap;
 }

 .checkbox {
     composes: cell;
     width: 5%;
     padding: 0;
 }

 .id {
     composes: cell;
     width: 5%;
     display: flex;
     justify-content: center;

 }

 .name {
     composes: cell;
     width: 22%;
 }

 .status {
     composes: cell;
     width: 8%;
 }

 .skipped {
     composes: cell;
     width: 10%
 }

 .priority {
     composes: cell;
     width: 7%;
     display: flex;
     justify-content: center;

 }

 .uploadedBy {
     composes: cell;
     width: 21%;
 }

.company {
    composes: cell;
    width: 10%;
}

 .statistics {
     composes: cell;
     width: 15%;

 }

 .actions {
     composes: cell;
 }


 .filters {
     display: flex;
     align-items: flex-start;
     padding: 20px 20px 0 0;
     flex-wrap: wrap;
 }

 .filters__swap {
     flex: 0;
     flex-basis: 293px;
     font-size: 14px;
     border-radius: 4px;
     height: 43px;
     border: 1px solid var(--intel-gray-light);
     font-family: var(--font-main);
     line-height: 1.5;
     transition: border-color 350ms ease-in-out;
     width: 100%;
     display: flex;
     align-items: center;
     margin-right: 20px;
     padding: 5px
 }

 .filters__swap_blocked {
     composes: filters__swap;
     pointer-events: none;
     opacity: 0.5;
     filter: grayscale();
 }

 .filters__swapInfo {
     flex: 2;
     min-width: 185px;
     font-weight: bolder;
     color: var(--intel-text);
     padding: 0px 12px;
 }

 .filters__swapButton {
     flex: 1;
     color: var(--intel-white);
     background: var(--intel-link);
     text-transform: uppercase;
     letter-spacing: .4px;
     transition: none;
     padding: 0 20px;
     transition: all .2s;
     border: 1px solid transparent;
     border-radius: 4px;
     font-family: Avenir, sans-serif;
     font-size: .8rem;
     font-weight: 900;
     cursor: pointer;
     align-self: stretch;
 }

 .filters__swapButton:first-of-type {
     margin-right: 3px;
 }

 .filters__swapButton:hover {
     background-color: rgba(100, 100, 300, .5);
     color: rgb(100, 100, 300);
     border: solid 1px rgb(100, 100, 300);
     cursor: pointer;
 }

 .filters__search {
     flex: 1;
     flex-basis: 293px;
 }

 .filters__byStatus {
    flex: 1;
    min-width: 118px;
}


 .popup {
     width: 100%;
     padding: 20px;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: center;
 }

 .popup__message {
     font-size: 16px;
 }

 .popup__name {
     font-size: 16px;
     font-weight: bolder;
 }

 .popup__actions {
     padding: 20px 0 40px 0;
 }

 .popup__open,
 .popup__close {
     background-color: transparent;
     border: none;
     padding: 5px;
     cursor: pointer;
 }


 .popup__open:hover {
     transform: scale(1.1)
 }

 .popup__open:active {
     transform: scale(0.9)
 }

 .popup__field {
     padding: 10px 0;
 }

 .filters__byId {
     flex: 1;
     min-width: 50px;
 }

 .filters__hiddenField {
     display: none;
 }

 .filters__header {
     width: 100%;
     padding-bottom: 20px;

 }

 .filters__headerInner {
     font-size: 13px;
     font-weight: bold;
 }