.root {
    height: 58px;
    width:100%;
    background-color: var(--intel-dark);
    display: flex;
    align-items: center;
}

.hamburger {
    width: 58px;
    height: 58px;
    color: white;
    background-color: transparent;
    border: none;
}

.hamburger::before {
    width: 58px;
    height: 58px;
    font-size: 25px;
    font-weight: normal;
}

.hamburger:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.3);
}

.title {
    color: var(--intel-white);
    font-size: 18px;
    font-family: "Avenir Book",serif;
    font-weight: normal;
    line-height: 18px;
    padding-left: 10px;
    width: calc(50% - 29px);
}

.logoContainer {
    width: calc(50% - 29px);
    height: 100%;
    display: flex;
    align-items: center
}
.logo {
    width: auto;
    height: calc((32 / 58) * 100%);
}
