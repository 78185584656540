.root {}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list__item {
    display: flex;
    font-family: var(--font-main);
    font-size: 14px;
}

.list__item:first-of-type {
    font-weight: 600;
}

.list__itemCell {
    padding: 20px 0;
}

.list__itemID {
    composes: list__itemCell;
    flex: 5;
}

.list__package {
    composes: list__itemCell;
    flex: 25;
}

.list__status {
    composes: list__itemCell;
    flex: 10;
}

.list__uploaded {
    composes: list__itemCell;
    flex: 20;
}

.list__completed {
    composes: list__itemCell;
    flex: 15;
}
.list__statistics{
    composes: list__itemCell;
    flex: 15;
}

.list__packageLabel {

}

.list__packageInput {
    padding: 5px 20px 0 0;
}