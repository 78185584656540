.logout {
    display: flex;
    color: var(--intel-white);
    font-size: 16px;
    padding: 0 20px;
    align-items: center;
}

.logout__label {
    white-space: nowrap;
    padding-right: 10px;
}

.logout__name {
    color: var(--intel-white);
    font-size: 16px;
    padding: 10px;
    line-height: 16px;
    background-color: transparent;
    cursor: pointer;
    border: solid 2px var(--bg);
    border-radius: 7px;
    margin-left: 10px;
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 40px 0;
}

.popup__actions {
    display: flex;
    justify-content: center;
    padding-top: 20px
}