.overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    z-index: 100;
    animation: fadein 300ms ease-out;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1
    }
}

.popup {
    width: 100%;
    max-width: 600px;
    background-color: var(--intel-white);
    border-radius: 10px;
    max-height: 100vh;
    overflow: auto;
}

.popup__action {
    display: flex;
    justify-content: flex-end;
}