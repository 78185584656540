.root {
    width: calc(100% - 20px);
    max-width: 1200px;
    background-color: var(--intel-white);
    margin: 10px auto;
}

.title {
    padding: 20px;
    font-family: var(--font-main);
    font-size: 14px;
    font-weight: 600;
    border-bottom: solid 2px var(--bg);
}

.content {
    padding: 20px;
}