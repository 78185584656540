.popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px
}
.popup__info{
    font-family: var(--font-main);
    font-size: 16px;
    padding-bottom: 20px;
    text-align: center;
}
.popup__info_stressed{
    font-family: var(--font-main);
    font-size: 16px;
    font-weight: bolder;
    padding-bottom: 20px;
}
.popup__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;
}