.root {
    width: 100%;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label__span {
    font-family: var(--font-main);
    font-size: 13px;
    font-weight: 700;
    color: var(--intel-gray-light);
    margin-bottom: 5px;
}

.input {
    font-size: 14px;
    border-radius: 4px;
    min-height: 40px;
    padding: 10px 12px;
    border: 1px solid var(--intel-gray-light);
    font-family: var(--font-main);
    line-height: 1.5;
    transition: border-color 350ms ease-in-out;
    width: 100%;
    display: block;
    background-color: var(--intel-white);
}

.input:focus {
    border-color: var(--intel-light);
    outline: none;
}

.input[value=""] {
    font-weight: bolder;
    color:  var(--intel-gray-light);
}

.input[value=""]::placeholder {
    transition: color 350ms ease-in-out;
    color:  var(--intel-gray-light);
}

.input[value=""]:focus::placeholder {
    color: var(--intel-light);
}
.input[data-error] {
    border-color: var(--intel-negative);
    
}
.message {
    font-family: var(--font-main);
    color: var(--intel-negative);
    font-size: 12px;
    margin: 7px 4px;
    font-weight: 400;
}
/*
            <div className={styles.root}>
                <label className={styles.label}>
                    <span className={styles.label__span}>
                        {label || "ello"}
                    </span>
                    <input
                        className={styles.input}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={setField}
                    />
                </label>
                <span className={styles.message}>
                    {message || "error"}
                </span>
            </div>
*/