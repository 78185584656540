.root {
    color: var(--intel-white);
    background: var(--intel-link);
    text-transform: uppercase;
    letter-spacing: .4px;
    transition: none;
    padding: 11px 20px;
    transition: all .2s;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: Avenir,sans-serif;
    font-size: .8rem;
    font-weight: 900;
    cursor: pointer;
}

.root:hover:not([disabled]) {
    background-color: rgba(100,100,300, .5);
    color: rgb(100,100,300);
    border: solid 1px rgb(100,100,300);
    cursor: pointer;
}

.root:active {
    background-color: rgba(100,100,300, .5);;
    color: rgb(100,100,300);
    border: solid 1px white;
    cursor: pointer;
}

[disabled] {
    pointer-events: none;
    opacity: 0.5;
}
