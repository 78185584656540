.root {
    max-width: 1600px;
    margin: 10px auto;
}

.message {
    padding: 30px 0;
}

.users__filters {
    padding-top: 20px;
}