.root {
    width: 200px;
    background-color: var(--intel-dark2);
    transition: transform ease-out 300ms;
    height: 100%;
    will-change: transform;
}
.rootExpanded {
    transform: translateX(0);
}

.rootFolded {
    transform: translateX(calc(-100% + 58px));
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    list-style: none;
    margin: 0;
}

.list__item {
    width: 100%;
    height: 50px;
}

.list__item > a,
.list__item > a:hover,
.list__item > a:active,
.list__item > a:visited {
    text-decoration: none;
}

.list__item:hover {
    background-color: var(--intel-link-hover);
    cursor: pointer
}

.list__itemAnchor {
    display: flex;
    height: 100%;
    align-items: center;
}

.list__itemIcon {    
    order:1;
    color : var(--intel-white);
    width: 58px;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.list__itemIcon::before {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.list__itemLabel {
    order:2;
    color: var(--intel-white);
    font-family: Avenir,sans-serif;
    font-weight: normal;
    font-size: 14px;
    opacity: 1;
    transition: opacity 300ms ease-out
}

.rootFolded .list__itemAnchor {
    justify-content: flex-end;
}

.rootFolded .list__itemLabel {
    order:0;
    opacity: 0;
}

.list__itemAnchor_active {
    background-color: var(--intel-link-hover);
}